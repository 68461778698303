import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import NavContext from "../Layout/Nav/NavProvider";
import { useInView } from "../../hooks/useInView";
import Image from "../Image";
import "./RelatedProjects.scss";
import { t } from "i18next";

type RelatedProjectType = {
  slug: any;
  name: string;
  brand: string;
  type: string;
  shortDescription: string;
  defaultImage: any;
};

type RelatedProjectsProps = {
  list: RelatedProjectType[];
  startsIn: number;
};

const RelatedProjects: FC<RelatedProjectsProps> = ({ list, startsIn }) => {
  const { isMobile } = useContext(NavContext);
  const isMobileActive = isMobile();
  const slider = useRef<HTMLDivElement>();
  const listItems = [...list, ...list, ...list];

  const inView = useInView(slider, {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  });

  const getImage = (item) => {
    return item.defaultImage ? (
      <Image
        image={item.defaultImage}
        title={item.name}
        className="related-projects__img"
      />
    ) : (
      <div className="related-projects__empty-img">
        <span className="related-projects__empty-label">
          {t("common.noImage")}
        </span>
      </div>
    );
  };

  return (
    <div
      ref={slider}
      className={`related-projects ${
        inView && isMobileActive ? "is-active" : ""
      }`}
    >
      <div className="related-projects__content">
        <h3 className="related-projects__title">{t("common.otherProjects")}</h3>
        <Swiper
          allowTouchMove
          slidesPerView={isMobile() ? 1 : 1.5}
          initialSlide={startsIn > 0 ? startsIn - 1 : startsIn}
          spaceBetween={isMobile() ? 16 : 20}
          speed={11000}
          loop
          loopFillGroupWithBlank
          autoplay={{
            delay: 0,
            waitForTransition: false,
            disableOnInteraction: true,
          }}
          modules={[Autoplay]}
        >
          {listItems.map((el) => (
            <SwiperSlide key={el.slug.current}>
              <div className="related-projects__item">
                <a
                  href={`/${el.slug.current}`}
                  className="related-projects__img-wrapper"
                >
                  {getImage(el)}
                  <div className="related-projects__item-content">
                    <span className="related-projects__brand">{el.brand}</span>
                    <span className="related-projects__name">{el.name}</span>

                    <div className="related-projects__row">
                      <div className="related-projects__col">
                        <span className="related-projects__subtitle">
                          {t("common.projectType")}
                        </span>
                        <p className='related-projects__paragraph'>{ el.type }</p>
                      </div>
                      <div className="related-projects__col">
                        <span className="related-projects__subtitle">
                          {t("common.smallDescription")}
                        </span>
                        <p className='related-projects__paragraph'>{ el.shortDescription }</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default RelatedProjects;
