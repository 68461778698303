import React, { FC, useRef } from "react";
import { t } from "i18next";
import { ProjectType } from "../../models/Project.model";
import { PortableText } from "@portabletext/react";

import Nut from "../Nut";
import DataPanel from "../DataPanel";
import DataPanelItem from "../DataPanel/DataPanelItem";
import { useInView } from "../../hooks/useInView";
import "./ProjectDetailContent.scss";

type ProjectDetailContentType = {
  project: ProjectType;
  goBackElement: JSX.Element;
  isMobilePrevSlideActive?: boolean;
};

export const ProjectDetailContent: FC<ProjectDetailContentType> = (props) => {
  const { project, goBackElement } = props;
  const projectDetailContent = useRef<HTMLDivElement>();

  const inView = useInView(projectDetailContent, {
    root: null,
    rootMargin: '100px',
    threshold: 0.1,
  }, true);

  const getStateClass = () => {
    return inView ? "is-active" : "is-prev";
  };

  return (
    <div
      ref={projectDetailContent}
      className={`project-detail-content ${getStateClass()}`}
    >
      {goBackElement}
      <div className="project-detail-content__wrapper">
        <p className="project-detail-content__section">
          {project.brand ? project.brand : t("projectDetail.sectionName")}
        </p>
        <h1 className="project-detail-content__name">{project.name}</h1>

        <div className="project-detail-content__data">
          <DataPanel isAnimated inView>
            <DataPanelItem title={t("projectDetail.type")}>
              {project.type}
            </DataPanelItem>
            <DataPanelItem title={t("projectDetail.shortDescription")}>
              {project.shortDescription}
            </DataPanelItem>
          </DataPanel>
        </div>

        <div className="project-detail-content__nut-wrapper">
          <div className="project-detail-content__nut">
            <Nut color={"#000000"} />
          </div>
        </div>
        <div className="project-detail-content__description scrollable custom-scrollbar">
          <PortableText value={project.description} />
        </div>
      </div>
    </div>
  );
};
