import "normalize.css";
import "./ProjectDetailTemplate.scss";

import React, { FC, useContext, useEffect, useRef, useState } from "react";

import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import NavContext, {
  NavProvider,
} from "../../components/Layout/Nav/NavProvider";

import HomeSliderContact from "../../components/HomeSliderContact";
import SectionHeader from "../../components/SectionHeader";
import Project from "../../components/Project";
import Slider from "../../components/Slider";
import { t } from "i18next";
import { graphql } from "gatsby";
import {
  getSectionSlug,
  PageTemplateProps,
  YouplanetSections,
} from "../../constants/general";
import { getCurrentLanguage } from "../../i18n";
import ArrowMoreContent from "../../components/ArrowMoreContent";
import { ProjectDetailContent } from "../../components/ProjectDetailContent";
import { useInView } from "../../hooks/useInView";
import { RelatedProjects } from "../../components/RelatedProjects";

const PageTemplate: FC<PageTemplateProps> = ({ data, pageContext }) => {
  const { project, projectList } = data as any;
  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);

  const { isMobile } = useContext(NavContext);

  const currentSlug = pageContext.slug;
  const currentLanguage = getCurrentLanguage();
  const projectListByLanguage = projectList.nodes.filter(x => x.slug.current.includes(`${currentLanguage}/`));

  const pageTitle = `${t("projectDetail.sectionName")} Youplanet - ${
    project.name
  }`;
  const description = `${t("projectDetail.sectionName")} Youplanet - ${
    project.name
  }`;
  const alternativeUrls = pageContext.alternativeUrls;

  const section = useRef<HTMLDivElement>();
  const projectDetailVideo = useRef<HTMLDivElement>();

  const inView = useInView(section, {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  });

  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile]);

  const goBackLink = () => {
    return `${getSectionSlug(
      YouplanetSections.ProjectList,
      getCurrentLanguage()
    )}`;
  };

  const goBackArrowLink = () => {
    return (
      <div className="project-detail__link">
        <a href={goBackLink()}>
          <ArrowMoreContent
            color="grey"
            containerHeight="2rem"
            containerWidth="16rem"
            sideWidth="1rem"
            width=".2rem"
            direction="left"
            clickHandler={null}
          />
        </a>
      </div>
    );
  };

  const projectVideo = () => {
    return (
      <div
        ref={projectDetailVideo}
        className={`project-detail__video is-active`}
      >
        {goBackArrowLink()}
        <Project
          title={project.name}
          videoId={project.youtubeId}
          mobileVideoId={project.mobileVideoId}
          titleTag="p"
          autoPlay
          isDetail
          customCursor
          isMobileAutoplayActive={inView}
          enableFullScreen
        />
      </div>
    );
  };

  function getList() {
    let list = project.relatedItems;

    if (project.relatedItems.length <= 0) {
      list = projectListByLanguage.filter((n) => n.name != project.name);
    }
    return list;
  }

  function pageContent(usePrevSlideOption?: boolean) {
    const elements: JSX.Element[] = [
      projectVideo(),
      <ProjectDetailContent
        isMobilePrevSlideActive={usePrevSlideOption}
        project={project}
        goBackElement={goBackArrowLink()}
      />,
      <RelatedProjects list={getList()}  startsIn={project.position} />,
      <HomeSliderContact />,
    ];
    return elements.map((element, index) => (
      <span key={`${index}-projectDetailTemplate`}>{element}</span>
    ));
  }

  return (
    <NavProvider>
      <Layout
        language={currentLanguage}
        alternativeUrls={alternativeUrls}
        isHome={false}
      >
        <SEO
          title={pageTitle}
          description={description}
          lang={currentLanguage}
          slug={currentSlug}
        />
        <SectionHeader />
        <section ref={section} className="project-detail">
          {isMobileActive ? (
            pageContent(inView)
          ) : (
            <Slider>{pageContent()}</Slider>
          )}
        </section>
      </Layout>
    </NavProvider>
  );
};

export default PageTemplate;

export const query = graphql`
  query ProjectDetailTemplateQuery($id: String!, $lang: String) {
    project: sanityProject(id: { eq: $id }) {
      name
      brand
      slug {
        current
      }
      type
      position
      shortDescription
      department
      description: _rawDescription
      youtubeId
      mobileYoutubeId
      relatedItems {
        name
        brand
        slug {
          current
        }
        type
        shortDescription
        defaultImage {
          ...ImageFragment
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $lang } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    projectList: allSanityProject(
      sort: { order: ASC, fields: [position] }
      filter: { i18n_lang: { in: [$lang, null] }, _id: { regex: "/^(?!drafts.).*/" } }
    ) {
      nodes {
        name
        brand
        slug {
          current
        }
        type
        shortDescription
        defaultImage {
        ...ImageFragment
        }
      }
    }
  }
`;
